
import './App.css';
import RouteComponent from './Components/RouteComponent'


function App() {
  return (
<div>
  <RouteComponent/>
</div>

  );
}

export default App;
