import React from 'react';

export default function About() {
  return (<div>
    <br/>
    <h2> &emsp;&emsp; I am a developer from Cincinnati, Ohio I am currently working
        with AWS and React to develop responsive and scalable web applications. This
        site will be used to display business solutions and showcase previous work.
        please contact me about any business or job related opportunities.I am currently
         an AWS Certified Cloud Practitioner and hope to take the Cloud Solutions Architect
         exam this year(2022)

</h2>
</div>);
}
